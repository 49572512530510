import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import { costAxis } from "../components/product/substrates/data";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import {
  toggleButtonSx,
  toggleContainerSx,
  textFieldSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  image,
  settingsElement,
  additionalSettings,
  toggle,
} from "../style/pages/plywood.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  schemeBtn,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import dataJson from "../data/substrates.json";

import { StoreContext } from "../context/store-context";

function Plywoodsubsrates() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "substrates/plywood" }
          name: { nin: ["main"] }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [diameter, setDiameter] = useState(null);
  const [thickness, setThickness] = useState([]);
  const [form, setForm] = useState(null);

  const [engravingText, setEngravingText] = useState("");

  const [holes, setHoles] = useState(false);
  const [thicknessHoles, setThicknessHoles] = useState(0);

  const [axios, setAxios] = useState(false);
  const [materialAxios, setMaterialAxios] = useState(null);
  const [thicknessAxios, setThicknessAxios] = useState([]);
  const [lengthAxios, setLengthAxios] = useState(0);


  const [legs, setLegs] = useState(false);

  const [quantity, setQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Данные для подложек из фанеры
  const plywoodData = dataJson.plywood;
  // Форма подложки
  const plywoodForm = plywoodData.form;
  // Размеры подложки
  const plywoodSize = plywoodData.size;

  // Обновить состояние для диаметра
  const handleDiameter = (event, newAlignment) => {
    setDiameter(newAlignment);
    setThickness([]);
  };

  // Обновить состояние для толщины
  const handleThickness = (event, newAlignment) => {
    const innerText = event.target.innerText;
    setThickness([newAlignment, innerText]);
  };

  // Обновить состояние для текста гравировки
  const handleEngraving = (event) => {
    setEngravingText(event.target.value);
  };

  // Обновить состояние для количества подложек
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Обновить состояние для формы
  const handleForm = (event, newAlignment) => {
    setForm(newAlignment);
  };

  // Обновить состояние ширина отверстия
  const handleHoles = (event) => {
    if (Number(event.target.value) >= 0) {
      setThicknessHoles(Number(event.target.value));
    } else return;
  };

  // Обновить состояние для материала
  const handleMaterialAxios = (event, newAlignment) => {
    setMaterialAxios(newAlignment);
    setThicknessAxios([]);
    setLengthAxios(0);
  };

  // Обновить состояние для толщины
  const handleThicknessAxios = (event, newAlignment) => {
    costAxis.filter((item) => {
      if (item.material === materialAxios) {
        item.cost.filter((item) => {
          if (item.thickness === newAlignment) {
            setThicknessAxios([item.cost, newAlignment]);
          }
          return false;
        });
      }
      return false;
    });
  };

  // Обновить состояние для длины центральной оси
  const handleLengthAxios = (event) => {
    if (Number(event.target.value) >= 0) {
      setLengthAxios(Number(event.target.value));
    } else return;
  };

  // Добавить первый набор подложек
  const addProduts = () => {
    const dataOrder = {
      diameter: diameter,
      thickness: thickness,
      form: form,
      engravingText: engravingText,
      quantity: quantity,
      thicknessHoles: thicknessHoles,
      materialAxios: materialAxios,
      thicknessAxios: thicknessAxios,
      lengthAxios: lengthAxios,
      legs: legs,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      substrates: {
        plywood: {
          bundle: [...store.substrates.plywood.bundle, dataOrder],
          totalPrice:
            store.substrates.plywood.totalPrice + dataOrder.totalAmount,
        },
        lhdf: { ...store.substrates.lhdf },
        acrylic: { ...store.substrates.acrylic },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      tieredstructures: { ...store.tieredstructures },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });

    setForm(null);
    setDiameter(null);
    setThickness([]);
    setEngravingText("");
    setThicknessHoles(0);
    setMaterialAxios(null);
    setThicknessAxios([]);
    setLengthAxios(0);
    setQuantity(0);
    setTotalAmount(0);

    setHoles(false);
    setAxios(false);
    setLegs(false);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.substrates.plywood.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.substrates.plywood.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      substrates: {
        plywood: {
          bundle: [...arrBundle],
          totalPrice: store.substrates.plywood.totalPrice - deductibleAmount,
        },
        lhdf: { ...store.substrates.lhdf },
        acrylic: { ...store.substrates.acrylic },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      stand: { ...store.stand },
    });
  };

  useEffect(() => {
    let x = thickness[0] || 0;
    let q = quantity || 0;
    let a = thicknessAxios[0] || 0;
    let la = lengthAxios || 0;
    let l = legs ? 100 : 0;

    if (quantity >= 100 && quantity < 300) {
      setTotalAmount(
        Math.ceil(x * q - x * q * 0.06 + a * la * q * q + q * l)
      );
    } else if (quantity >= 300) {
      setTotalAmount(
        Math.ceil(x * q - x * q * 0.1 + a * la * q * q + q * l)
      );
    } else {
      setTotalAmount(Math.ceil(x * q + a * la * q * q + q * l));
    }
  }, [
    quantity,
    thickness,
    thicknessAxios,
    lengthAxios,
    legs,
  ]);

  return (
    <main className="mainpage">
      <MetaTag title="Подложки из фанеры | Wowmilota" />
      <motion.div
        layoutId="plywoodContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img, index) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.98 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотография подложки из фанеры"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <div className={detailHeader}>
                  <h2>Подложки из фанеры</h2>
                </div>
                <p>
                  Наши подложки изготовленны из фанеры сорта ФК. Идеальная
                  гладкость подложек достигается дополнительным шлифованием
                  каждой подложки. Возможно нанесение гравировки вашего логотипа
                  или любого текста, подготовка макета несложной гравировки
                  БЕСПЛАТНО
                  <br />
                  <br />
                </p>
                <p>
                  При заказе от 100 шт подложек скидка 6%
                  <br />
                  При заказе от 300 шт подложек скидка 10%
                  <br />
                  Скидка применяется автоматически при формировании заказа
                  <br />
                  <br />
                </p>
                <p>
                  <span>
                    Если вам необходимы другие параметры, вы можете оставить
                    свои пожелания при отправке заявки в поле комментарий к
                    заказу
                  </span>
                </p>
                <p>
                  Схема сборки многоярусного торта и расчет толщины подложки{" "}
                  <a
                    href="/pdf/plywood_scheme.pdf"
                    target="_blank"
                    className={schemeBtn}
                  >
                    открыть
                  </a>
                </p>
              </div>

              <h3 className={titleSettings}>Обязательные параметры</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: diameter
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Диаметр (см)</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={diameter}
                    exclusive
                    onChange={handleDiameter}
                    sx={toggleContainerSx}
                  >
                    {plywoodSize.map((item) => {
                      const { diameter } = item;

                      return (
                        <ToggleButton
                          value={diameter}
                          key={`diameter${diameter}`}
                          sx={toggleButtonSx}
                        >
                          {diameter}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      thickness[0] > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Толщина (мм)</h3>
                  {diameter ? (
                    plywoodSize
                      .filter((item) => {
                        if (item.diameter === diameter) {
                          return item;
                        } else {
                          return null;
                        }
                      })
                      .map((item) => {
                        const { three, four, six, eight } = item;

                        return (
                          <ToggleButtonGroup
                            size="medium"
                            value={thickness}
                            exclusive
                            onChange={handleThickness}
                            sx={toggleContainerSx}
                            key={`thicknessToggle`}
                          >
                            {three && (
                              <ToggleButton value={three} sx={toggleButtonSx}>
                                3
                              </ToggleButton>
                            )}
                            {four && (
                              <ToggleButton value={four} sx={toggleButtonSx}>
                                4
                              </ToggleButton>
                            )}
                            {six && (
                              <ToggleButton value={six} sx={toggleButtonSx}>
                                6
                              </ToggleButton>
                            )}
                            {eight && (
                              <ToggleButton value={eight} sx={toggleButtonSx}>
                                8
                              </ToggleButton>
                            )}
                          </ToggleButtonGroup>
                        );
                      })
                  ) : (
                    <p>Сначала выберите диаметр</p>
                  )}
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: form
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Форма</h3>
                  <ToggleButtonGroup
                    size="medium"
                    value={form}
                    exclusive
                    onChange={handleForm}
                    sx={toggleContainerSx}
                  >
                    {plywoodForm.map((item) => {
                      return (
                        <ToggleButton
                          value={item}
                          key={`form${item}`}
                          sx={toggleButtonSx}
                        >
                          {item}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество подложек</h3>
                  <div className={toggle}>
                    <TextField
                      id="plywoodQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Дополнительные параметры</h3>

              <div className={additionalSettings}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: engravingText
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Гравировка</h3>
                  <div className={toggle}>
                    <TextField
                      fullWidth
                      id="plywoodEngravingText"
                      label="Кратко опишите"
                      variant="standard"
                      value={engravingText}
                      onChange={handleEngraving}
                      sx={textFieldSx}
                    />
                  </div>
                </div>

                {holes ? (
                  <div
                    className={settingsElement}
                    style={{
                      backgroundColor: thicknessHoles
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                    }}
                  >
                    <h3>Диаметр доп. отверстий (мм)</h3>
                    <div className={toggle}>
                      <TextField
                        id="plywoodThicknessHoles"
                        variant="standard"
                        type="number"
                        onFocus={(e) =>
                          e.target.value === "0" && setThicknessHoles("")
                        }
                        onChange={handleHoles}
                        value={thicknessHoles}
                        sx={textFieldSx}
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={settingsElement}
                    style={{
                      backgroundColor: thicknessHoles
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                    }}
                  >
                    <h3>Дополнительные отверстия</h3>
                    <div className={toggle}>
                      <ToggleButton
                        value="check"
                        sx={toggleButtonSx}
                        onChange={() => setHoles(true)}
                      >
                        Добавить
                      </ToggleButton>
                    </div>
                  </div>
                )}

                {axios ? (
                  <>
                    <div
                      className={settingsElement}
                      style={{
                        backgroundColor: materialAxios
                          ? "rgba(255, 255, 255, 0.15)"
                          : "rgba(255, 255, 255, 0.05)",
                      }}
                    >
                      <h3>Материал оси</h3>
                      <ToggleButtonGroup
                        size="medium"
                        value={materialAxios}
                        exclusive
                        onChange={handleMaterialAxios}
                        sx={toggleContainerSx}
                      >
                        <ToggleButton
                          value="Дерево"
                          key="treeMaterial"
                          sx={toggleButtonSx}
                        >
                          Дерево
                        </ToggleButton>
                        <ToggleButton
                          value="Метал"
                          key="metalMaterial"
                          sx={toggleButtonSx}
                        >
                          Метал
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>

                    <div
                      className={settingsElement}
                      style={{
                        backgroundColor:
                          thicknessAxios[0] > 0
                            ? "rgba(255, 255, 255, 0.15)"
                            : "rgba(255, 255, 255, 0.05)",
                      }}
                    >
                      <h3>Толщина оси (мм)</h3>
                      {materialAxios ? (
                        <ToggleButtonGroup
                          size="medium"
                          value={thicknessAxios}
                          exclusive
                          onChange={handleThicknessAxios}
                          sx={toggleContainerSx}
                        >
                          {costAxis
                            .filter((item) => item.material === materialAxios)
                            .map((item) => {
                              const { cost } = item;

                              return cost.map((cost) => (
                                <ToggleButton
                                  value={cost.thickness}
                                  key={`thickness${cost.thickness}`}
                                  sx={toggleButtonSx}
                                >
                                  {cost.thickness}
                                </ToggleButton>
                              ));
                            })}
                        </ToggleButtonGroup>
                      ) : (
                        <p>Выберите материал оси</p>
                      )}
                    </div>

                    <div
                      className={settingsElement}
                      style={{
                        backgroundColor:
                          lengthAxios > 0
                            ? "rgba(255, 255, 255, 0.15)"
                            : "rgba(255, 255, 255, 0.05)",
                      }}
                    >
                      <h3>Длина оси (см)</h3>
                      {materialAxios && thicknessAxios[0] > 0 ? (
                        <div className={toggle}>
                          <TextField
                            id="centralaxisLength"
                            variant="standard"
                            type="number"
                            onFocus={(e) =>
                              e.target.value === "0" && setLengthAxios("")
                            }
                            onChange={handleLengthAxios}
                            value={lengthAxios}
                            sx={textFieldSx}
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        </div>
                      ) : (
                        <p>Выберите материал и толщину оси</p>
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    className={settingsElement}
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.05)" }}
                  >
                    <h3>Центральная ось</h3>
                    <div className={toggle}>
                      <ToggleButton
                        value="check"
                        sx={toggleButtonSx}
                        onChange={() => setAxios(true)}
                      >
                        Добавить
                      </ToggleButton>
                    </div>
                  </div>
                )}

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: legs
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Ножки</h3>
                  <div className={toggle}>
                    <ToggleButton
                      value="check"
                      selected={legs}
                      sx={toggleButtonSx}
                      onChange={() => setLegs(!legs)}
                    >
                      {legs ? `Добавлено` : `Добавить`}
                    </ToggleButton>
                  </div>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              {store?.substrates.plywood.totalPrice > 0 ? (
                <>
                  <h3>Ваши наборы</h3>
                  <div className={productContainer}>
                    {store?.substrates.plywood.bundle.map((item, index) => {
                      return (
                        <div key={`order_${index}`} className={productItem}>
                          <button
                            className={delProduct}
                            aria-label="Удалить"
                            onClick={() => deleteProduct(index)}
                          >
                            <MdOutlineClose color="white" />
                          </button>
                          <h3>Набор {index + 1}</h3>
                          <p>
                            {item.diameter} см / {item.thickness[1]} мм
                          </p>
                          <p>{item.form}</p>
                          {item.engravingText ? (
                            <p>С гравировкой</p>
                          ) : (
                            <p>Без гравировкой</p>
                          )}
                          {item.thicknessHoles > 0 && (
                            <p>Диаметр отверстия: {item.thicknessHoles} мм</p>
                          )}
                          {item.materialAxios && (
                            <p>Материал оси: {item.materialAxios}</p>
                          )}
                          {item.thicknessAxios[0] && (
                            <p>Толщина оси: {item.thicknessAxios[1]}</p>
                          )}
                          {item.lengthAxios > 0 && (
                            <p>Длина оси: {item.lengthAxios} мм</p>
                          )}
                          {item.lenghtSupports > 0 && (
                            <p>Длина опоры: {item.lenghtSupports} см</p>
                          )}
                          {item.quantitySupport > 0 && (
                            <p>Количество опор: {item.quantitySupport} см</p>
                          )}
                          {item.legs && <p>Добавлены ножки</p>}
                          <p>{item.quantity} шт.</p>
                          <p>{item.totalAmount} руб.</p>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className={priceInfo}>
            <div className={mobileProducts}>
              <button onClick={() => setMobileList(!mobileList)}>
                <MdShoppingBasket color="white" />
                <p className={nubmer}>
                  {store?.substrates.plywood.bundle.length}
                </p>
              </button>
              {mobileList && (
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={10}
                  grabCursor={true}
                  className={productContainer}
                >
                  {store?.substrates.plywood.bundle.map((item, index) => {
                    return (
                      <SwiperSlide
                        key={`order_${index}`}
                        className={productItem}
                      >
                        <button
                          className="delProduct"
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>
                          {item.diameter} см / {item.thickness[1]} мм
                        </p>
                        <p>{item.form}</p>
                        {item.engravingText ? (
                          <p>С гравировкой</p>
                        ) : (
                          <p>Без гравировкой</p>
                        )}
                        {item.thicknessHoles > 0 && (
                          <p>Диаметр отверстия: {item.thicknessHoles} мм</p>
                        )}
                        {item.materialAxios && (
                          <p>Материал оси: {item.materialAxios}</p>
                        )}
                        {item.thicknessAxios[0] && (
                          <p>Толщина оси: {item.thicknessAxios[1]} мм</p>
                        )}
                        {item.lengthAxios > 0 && (
                          <p>Длина оси: {item.lengthAxios} мм</p>
                        )}
                        {item.lenghtSupports > 0 && (
                          <p>Длина опоры: {item.lenghtSupports} см</p>
                        )}
                        {item.quantitySupport > 0 && (
                          <p>Количество опор: {item.quantitySupport} шт.</p>
                        )}
                        {item.legs && <p>Добавлены ножки</p>}
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
            {totalAmount > 0 && form ? (
              <button className={costBundle} onClick={addProduts}>
                {totalAmount > 0 && form
                  ? `Добавить набор ${totalAmount} руб.`
                  : "Сформируйте набор"}
              </button>
            ) : (
              <p className={costBundle}>Сформируйте набор</p>
            )}
            <Link to="/" className={mobCloseBtn}>
              <MdOutlineClose color="white" />
            </Link>
            <Link to="/" className={closeBtn}>
              {store?.substrates.plywood.bundle.length > 0
                ? `Сохранить и закрыть`
                : `закрыть`}
            </Link>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Plywoodsubsrates;
