// Диаметр подложки
// Толщина подложки
// Форма подложки
// Гравировка подложки

// Толщина отверстия

// Комплект ножек (количество)

// Нужна ли ось
// Материал оси
// Длина оси
// Толщина оси

// Опора (да, нет)
// Длина опоры (если да)

// Количество подложек

export const marks = [
  {
    value: 0,
    label: "0 см",
  },
  {
    value: 15,
    label: "15 см",
  },
  {
    value: 20,
    label: "20 см",
  },
];

export const costAxis = [
  {
    material: "Дерево",
    cost: [
      {
        thickness: 10,
        cost: 2.8,
      },
      {
        thickness: 15,
        cost: 2.8,
      },
    ],
  },
  {
    material: "Метал",
    cost: [
      {
        thickness: 10,
        cost: 5,
      },
      {
        thickness: 8,
        cost: 4,
      },
    ],
  },
];
